.login-container {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease-in-out;
    margin-top: 30px;
}

.logo-img {
    margin-bottom: 50px;
}

h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 40px;
    animation: slideIn 1s ease-in-out;
}

.form-group {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: left;
    animation: fadeIn 1s ease-in-out;
}


input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    animation: slideIn 1s ease-in-out;
}


.user-type-buttons{
    color: #fff;
    padding: 10px;
    margin-top: 40px;
    animation: slideIn 1s ease-in-out;
    display: flex;
    justify-content: space-between;
    width: 500px;
}

.login-Back-button{
    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 8px 16px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 170px;
    left: 10px;
}

.login-Back-button:hover {
    background-color: #333;
    color: #fff;
}

.space {
    width: 100px;
}

.login-header-color{
    background-color: #BF3C23; 
}

.login-as-casheir{
    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 8px 16px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;
}

.login-as-casheir:hover{
    background-color: #333;
    color: #fff;
}

.login-as-manager{
    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 8px 16px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;
}

.login-as-manager:hover{
    background-color: #333;
    color: #fff;
}



@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(-20px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
