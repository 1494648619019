.Back{
    font-size: 20px;
    padding: 20px;
    position: absolute;
    top: 50px;
    left: 20px;

    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 8px 16px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;

    transition: background-color 0.3s;
}

.Back:hover {
    background-color: #333;
    color: #fff;
}

.pay-now{
    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 8px 16px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;
    width: 200px;
    transition: background-color 0.3s;
}

.pay-now:hover{
    background-color: rgb(65, 106, 160);
    color: #fff;
}

.clear-chart{
    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 8px 16px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;
    width: 200px;
    transition: background-color 0.3s;
}

.clear-chart:hover{
    background-color: rgb(160, 65, 65);
    color: #fff;
}

.name-input{
    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 8px 16px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;
    width: 200px;
}
