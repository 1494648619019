.f_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  margin-top: 20px;
}

.buttons {
  margin-top: 10px;
  text-align: center; 
}


.startOrderButton {
  text-decoration: none;
  color: #333;
  margin: 10px;
  padding: 10px 20px;
  border: 2px solid #333;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.3s;
  font-size: 20px;
}

.startOrderButton:hover {
  background-color: rgb(192, 86, 68); 
  color: white;
}

.adminLoginButton {
  text-decoration: none;
  color: #333;
  margin: 10px;
  padding: 8px 16px;
  border: 2px solid #333;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  left: 444px;
  top: 20px;
}

.adminLoginButton:hover {
  background-color: #333;
  color: #fff;
}

.newPageButton {
  text-decoration: none;
  color: #333;
  margin: 10px;
  padding: 8px 16px;
  border: 2px solid #333;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
  right: 444px;
  top: 20px;
}


.newPageButton:hover {
  background-color: #333;
  color: #fff;
}

.contact {
  margin-top: 10px; 
  text-align: center;
}

.contact h2 {
  margin-top: 30px;
  color: #333;
  position: relative;
  top: 20px;
}

.contact p {
  margin: 5px;
}

.f-login-img{
  margin-bottom: 50px;
}

.f_header{
  margin-top: 40px;
  margin-bottom: 30px;
}


  