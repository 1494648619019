.navbar-brand{
    font-family: "Georgia", serif;
}


.header-logo {
    max-height: 120px; /* This ensures the image maintains its aspect ratio */
}
  
.navbar {
  max-height: 130px; /* Adjust this value for the max height of the header */
  /* Add other styling as needed */
}

.custom-bg-color {
  background-color: #BF3C23; 
}

