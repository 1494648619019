.weather-icon {
    max-width: 50px;
    max-height: 50px;
    width: 5vw;
    height: 5vw;
  }
  
.bold-white-text {
    color: white;
    font-weight: bold;
}
  
.weather {
  padding: 20px;
}