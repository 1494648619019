.manager_front_overlay{
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
}

.manager_front_buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  margin-top: 30px; /* Adjust the margin to a smaller value */
  
}


.manager_front_buttons a {
  text-decoration: none;
  color: #333;
  margin: 20px; /* Adjust the margin to a smaller value */
  padding: 20px 16px;
  border: 2px solid #333;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 200px;
  text-align: center;
}

.manager_front_buttons a:hover {
  background-color: #333;
  color: #fff;
}


.manager_front_title{
  margin-top: 40px;
}

.manager-header-color{
  background-color: #BF3C23; 
}