.menu {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-row-gap: 0px;
    gap: 0px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  .item-image {
    width: 300px;
    height: 380px;
    object-fit: contain;
    overflow: hidden;
    filter: blur(1px);
    max-height: 350px;
    max-width: 100%;
  }
  
  .item-image img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .item-details {
    position: relative; 
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding: 20px;
    z-index: 1;
  }
  
  .item-name {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .item-description {
    font-size: 16px;
    margin: 10px 0;
  }
  
  .item-price {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
  }
  