.center-info{
    margin-top: 20px;
    margin-bottom: 20px;
}

.center-info div {
    margin-bottom: 10px;
}
  
.center-info input {
    text-align: center; 
    line-height: 2; 
}

.navi_button{
    margin-left: 20px;
    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 8px 16px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;
}

.navi_button:hover{
    background-color: #333;
    color: #fff;
}

.logo-img-edit{
    margin-bottom: 15px;
}

.edit-header{
    margin-bottom: 15px;
}

.edit-bottons{
    margin-bottom: 10px;
    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 8px 16px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;
}

.edit-bottons:hover{
    background-color: #333;
    color: #fff;
}


.edit-manager-navigation {
    position: absolute;
    top: 200px;
    left: 0px;
    padding: 10px;
  }

.show{
    display: block;
}

.show .modal-content {
    width: 400px;
    margin: 0 auto; 
    padding: 20px;
    background-color: #fff; 
    border-radius: 8px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


.ingredient-checkboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; /* Adjust alignment as needed */
    height: 400px;
  }
  
  .ingredient-checkbox {
    display: flex;
    align-items: center;
    width: 20%; /* Adjust the width as needed */
    margin-bottom: 100px;
    margin-top: 20px;
}
  
  .ingredient-checkbox label {
    flex-grow: 1; /* Allow the label to take up remaining space */
    width: 300px;
  }


