.paied-Report-Button{
  text-decoration: none;
  color: #333;
  margin: 10px;
  padding: 8px 16px;
  border: 2px solid #333;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.paied-Report-Button:hover {
  background-color: #333;
  color: #fff;
}