.trends_navi_button {
  position: absolute;
  top: 180px;
  left: 0;
  padding: 10px;
}

.trends-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  font-size: 18px;
}

.trends_navi_button a{
  text-decoration: none;
  color: #333;
  margin: 10px;
  padding: 8px 16px;
  border: 2px solid #333;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.trends_navi_button a:hover {
  background-color: #333;
  color: #fff;
}

.top-section {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.top-section button {
  text-decoration: none;
  color: #333;
  margin: 10px;
  padding: 8px 16px;
  border: 2px solid #333;
  border-radius: 4px;
  cursor: pointer;
}

.top-section button:hover {
  background-color: #333;
  color: #fff;
}

.buttom-section {
  width: 90%;
  height: 90%;
}

#textField {
  padding-top: 1px;
  font-size: 20px;
  height: 90%;
  text-align: center;
}


