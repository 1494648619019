/* styles.css */
body {
    background-color: #f4f4f4;
  }
  
  th {
    text-align: center;
    padding: 20px;
  }
  

  table {
    margin: 0 auto; 
    border: 1px solid black;
  }
  
  .button_class {
    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 8px 16px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;
}

  .button_class:hover{
    background-color: #333;
    color: #fff;
  }
  
    .table-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        /*background-image: url('https://besthqwallpapers.com/Uploads/1-11-2019/110193/thumb2-4k-red-chinese-background-wavy-chinese-patterns-chinese-ornament-background-chinese-patterns.jpg'); Replace with your image path */
        /*background-repeat: repeat-y;  Repeat vertically */
        /*background-size: auto 100%; Adjust as needed */
        padding: 10px; /* Add some padding to separate the content from the border */
      }

      .table-container table {
        border-collapse: collapse;
        width: 100%;
      }

      .table-container th, .table-container td {
        border: 1px solid #ddd; /* Regular border as fallback */
        padding: 8px; /* Adjust as needed */
        background-color: white; /* Set a background color for the cells */
      }
      
      /* Style the "Add Item" button */
      .table-container .add-button {
        text-decoration: none;
        color: #333;
        margin: 10px;
        padding: 8px 16px;
        border: 2px solid #333;
        border-radius: 4px;
        cursor: pointer;
      }
      
      .table-container .add-button:hover {
        background-color: #333;
        color: #fff;
      }

      .remove_button {
        text-decoration: none;
        color: #333;
        margin: 10px;
        padding: 8px 16px;
        border: 2px solid #333;
        border-radius: 4px;
        cursor: pointer;
    }
      

    .table-container .remove_button:hover{
    background-color: #fa4d6a;
    color:black;
  }
  
  .confirmation-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .confirmation-modal {
    background: #fff; /* White background for the modal */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  .confirmation-modal-buttons{
    display: flex;
    justify-content: center;
  }

  .confirmation-modal-buttons button{
    padding: 10px 20px;
    margin: 0 5px;
  }

  .update-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .update-modal {
    background: #fff; /* White background for the modal */
    padding: 40px;
    border-radius: 8px;
    text-align: center;
  }
  
  .update-modal-buttons{
    display: flex;
    justify-content: center;
  }

  .update-modal-buttons button{
    padding: 10px 20px;
    margin: 10px 5px;
  }

  .add-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .add-modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-modal h2 {
    margin-bottom: 20px;
  }
  
  .add-modal form {
    display: grid;
    gap: 10px;
  }
  
  .add-modal label {
    font-weight: bold;
  }
  
  .add-modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .logo-img-manager{
    margin-bottom: 10px;
  }


  .manager-navigation-bottons {
    position: absolute;
    top: 160px;
    left: 0px;
    padding: 10px;
    
  }
  
  .manager-navigation-bottons button {
    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 8px 16px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .manager-navigation-bottons button:hover {
    background-color: #333;
    color: #fff;
  }

  .custom-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /*Needs to be on top of everything except alert*/
  }
  
  .custom-alert {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1001; 
  }
  
  
  .custom-alert button {
    background-color: #6fa6e1;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
  }
  
  .custom-alert button:hover{
    background-color: #4d94e1;
  }

  .prevent{
    animation: none;
  }

  