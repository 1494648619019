body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pos-item{
  cursor: pointer;
  font-weight: bold;
}

.pos-item:hover{
  background: rgb(2, 85, 162);
  color: white;
}

.pos-item img{
  width: auto; 
  max-width: 100%;
  max-height: 200px;
  height: auto; 
  object-fit: contain; 
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-container {
  display: flex;
  justify-content: space-around; /* Or space-between or space-evenly */
}

.btn-container button {
  flex: 1; /* This makes each button take up equal space */
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}


.out-of-stock {
  opacity: 0.35;
  pointer-events: none; /* Disables clicking on the item */
}

.cash-Back{
  font-size: 20px;
  position: absolute;
  top: 50px;
  left: 20px;
  text-decoration: none;
  color: #333;
  margin: 10px;
  padding: 8px 16px;
  border: 2px solid #333;
  border-radius: 4px;
  cursor: pointer;

  transition: background-color 0.3s;
}

.cash-Back:hover {
  background-color: #333;
  color: #fff;
}

.categoryButton {
  margin : 5px;
  text-decoration: none;
  color: #333;
  margin: 10px;
  padding: 6px 12px;
  border: 2px solid #333;
  border-radius: 4px;
  cursor: pointer;
}

.categoryButton:hover{
  background-color: #333;
  color: #fff;
}