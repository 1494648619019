.check-out-button{
    font-size: 24px;
    padding: 18px;
    position: absolute;
    /*
    top: 95px;
    left: 1200px;
    */ 
    top: 180px;
    left: 80%;
    background-color: #d15d54; 
    color: #fff; 
    border: none;
    border-radius: 20px;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s;
}

.check-out-button:hover {
    background-color: rgb(135, 12, 3);
    color: white;
}

.cust-Back{
    font-size: 20px;
    padding: 20px;
    position: absolute;
    top: 50px;
    left: 20px;

    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 8px 16px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;

    transition: background-color 0.3s;
}

.cust-Back:hover {
    background-color: #333;
    color: #fff;
}

.pos-item{
    top: 700px;
    left: 400px;
}

.pos-item img{
    width: auto; 
    max-width: 100%;
    max-height: 200px;
    height: auto; 
    object-fit: contain; 
  }

  .out-of-stock {
    opacity: 0.35;
    pointer-events: none; /* Disables clicking on the item */
  }

  .categoryButton {
    margin : 5px;
    text-decoration: none;
    color: #333;
    margin: 10px;
    padding: 6px 12px;
    border: 2px solid #333;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .categoryButton:hover{
    background-color: #333;
    color: #fff;
  }