/* SalesReport.css */

.sales-data-list {
  list-style: none;
  padding: 0;
}

.sales-data-list-item {
  margin-bottom: 20px;
}

.Report-Button{
  margin-bottom: 30px;
  text-decoration: none;
  color: #333;
  margin: 10px;
  padding: 8px 16px;
  border: 2px solid #333;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.Report-Button:hover{
  background-color: #333;
  color: #fff;
}

.form-label {
  display: inline-block;
  width: auto; /* Adjust the width as needed */
  margin-right: 20px; /* Adjust the margin as needed */
}

.form-input {
  width: 150px; /* Adjust the width as needed */
  margin-bottom: 10px; /* Adjust the margin as needed */
}

.centered-sales-list{
  text-align: center;
}

.centered-sales-list ul {
  list-style: none;
  padding: 0;
}

.form-input{
  width: auto;
}

.Reported-Button{
  margin-top: 30px;
  margin-bottom: 30px;
  text-decoration: none;
  color: #333;
  margin: 10px;
  padding: 8px 16px;
  border: 2px solid #333;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.Report-Button:hover{
  background-color: #333;
  color: #fff;
}